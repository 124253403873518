import { store } from "../config/reduxConfig";
import {
  handleError,
  sendMessageToSentry,
  sendBreadcrumbToSentry,
  breadcrumbData,
  isObject,
  getEmailsFromAllLoggedInEmails,
} from "./commonUsefulFunctions";
import { getCurrentUserEmail } from "../lib/localData";
import appBroadcast from "../broadcasts/appBroadcast";

export class ErrorWithResponse extends Error {
  response?: Response
}

type HttpMethod = "DELETE" | "GET" | "PATCH" | "POST"

export const checkStatus = (response: Response) => {
  if (!isObject(response)) {
    sendMessageToSentry(
      "Null response",
      response ? response.toString() : "Fetcher null response",
    );
  } else if (response.ok) {
    return response;
  } else if (response.status?.toString() === "401") {
    const state = store.getState();
    const reduxEmail = state?.currentUser?.email;

    const loggedInAccountEmails = getEmailsFromAllLoggedInEmails();

    sendMessageToSentry(
      "401 log out",
      `currentUser: ${
        reduxEmail ?? getCurrentUserEmail() ?? "no current redux user email"
      } | loggedInAccounts: ${loggedInAccountEmails}`,
    );

    appBroadcast.publish("CLICK_LOG_OUT");
  } else if (
    response.status &&
    response.status.toString() === "403" &&
    response.url &&
    response.url.includes("zoom")
  ) {
    return response;
  } else {
    const error = new ErrorWithResponse(response.statusText);
    error.response = response;
    throw error;
  }
};

export const parseJSON = <T, >(response?: Response): Record<string, never> | Promise<T | Record<string, never>> => {
  if (!response?.json) {
    sendMessageToSentry("Error in parsing response3");
    sendMessageToSentry("response3 parseJSON response", response);
    return {};
  }

  // TODO: If there's an error with the request or with parsing the response to JSON, should we
  // reject the promise instead of resolve? Would probably make error-handling easier.
  // Could be a breaking change, may be better to make a v2 of fetcher and migrate incrementally.
  return new Promise<T | Record<string, never>>(function (resolve, reject) {
    try {
      // Comments below for testing:
      // let stream = new s.Readable();
      // stream.push('<html>content</html>');
      // stream.push(null);

      return response
        .json()
        .then((json: T) => {
          resolve(json);
        })
        .catch((err) => {
          sendMessageToSentry("Error in parsing response0", err);
          handleError(err);

          resolve({});
        });
    } catch (e) {
      sendMessageToSentry("Error in parsing response1", e);
      handleError(e);

      resolve({});
    }
  }).catch((e) => {
    sendMessageToSentry("Error in parsing response2", e);
    handleError(e);

    return {};
  });
};

const vimcalFetch = <T, >(
  path: string,
  params: RequestInit,
  method: HttpMethod,
  authorizationRequired = true,
  email: string | null = null,
  onErr?: (error: ErrorWithResponse) => void,
  connectedAccountToken?: string,
) => {
  sendBreadcrumbToSentry({
    category: "Fetch",
    message: path,
    data: {
      params: breadcrumbData(params),
      method,
      authorizationRequired,
      email,
    },
    level: undefined,
  });
  return fetch(
    path,
    constructParams(params, method, authorizationRequired, email, path, connectedAccountToken),
  )
    .then(checkStatus)
    .then(resp => parseJSON<T>(resp))
    .catch((err: ErrorWithResponse) => {
      // removed sentry error here because it was getting called too much and isn't useful
      // handleError(err);
      if (onErr) {
        onErr(err);
      }
    });
};

/**
 * Create a generically-typed method so that we can anticipate the type of the response.
 */
function httpMethodGenerator(method: HttpMethod) {
  return <T = Record<string, never>>(
    path: string,
    params: RequestInit = {},
    authorizationRequired = true,
    email: string | null = null,
    onErr?: (error: ErrorWithResponse) => void,
    connectedAccountToken?: string,
  ) => (
    vimcalFetch<T>(path, params, method, authorizationRequired, email, onErr, connectedAccountToken)
  );
}

const Fetcher = {
  get: httpMethodGenerator("GET"),
  post: httpMethodGenerator("POST"),
  patch: httpMethodGenerator("PATCH"),
  delete: httpMethodGenerator("DELETE"),
};

const constructParams = (
  params: RequestInit,
  verb: string,
  authorizationRequired: boolean,
  email: string | null = null,
  path = "",
  connectedAccountToken?: string,
): RequestInit => {
  if (!params["headers"]) {
    params["headers"] = {};
  }

  params["method"] = verb;

  // When submitting multipart/form-data, allow fetch to generate the full content type based on the body.
  // Otherwise the header may be incomplete.
  // https://github.com/JakeChampion/fetch/issues/505#issuecomment-293064470
  // https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
  if (!params["headers"]["Content-Type"]) {
    params["headers"]["Content-Type"] = "application/json";
  } else if (params["headers"]["Content-Type"] === "multipart/form-data") {
    delete params["headers"]["Content-Type"];
  }

  params["headers"]["X-VIMCAL-USER-CLIENT"] = "web";

  const state: { currentUser?: User } = store.getState();
  let currentUser: string | null = null;

  if (email) {
    currentUser = email;
  } else if (state && state.currentUser && state.currentUser.email) {
    currentUser = state.currentUser.email;
  } else if (getCurrentUserEmail()) {
    currentUser = getCurrentUserEmail();
  }

  if (authorizationRequired && currentUser) {
    params["headers"]["X-VIMCAL-USER-EMAIL"] = currentUser;
    params["credentials"] = "include";

    /* Don't add the header if token doesn't exist since backend reads null as a string */
    if (connectedAccountToken) {
      params["headers"]["X-VIMCAL-CONNECTED-ACCOUNT-TOKEN"] = connectedAccountToken;
    }
  }

  return params;
};

export default Fetcher;
