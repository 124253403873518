// doc on variables for outlook and gcal
// https://docs.google.com/spreadsheets/d/1PSYQkkcFs81FRUe6EmakUVCkfxCQ9IFiNFV6K0GD998/edit#gid=0
import { isOutOfOfficeEvent, isOutlookEvent } from "../lib/eventFunctions";
import {
  OUT_OF_OFFICE_AUTO_DECLINE_NONE,
  OUT_OF_OFFICE_DURING_EVENT,
} from "./googleCalendarService";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isTypeObject,
} from "./typeGuards";
import {
  VERSION_2,
  getAppVersion,
  isVersionV2,
} from "./versionFunctions";
import { getObjectEmail } from "../lib/objectFunctions";

const BACKEND_VERSION = getAppVersion();

export function getEventLocation(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      const location = event?.location ?? getEventLocationV1(event);
      if (isTypeObject(location) && location?.displayName) {
        return location.displayName;
      }

      return location;
    default:
      return getEventLocationV1(event);
  }
}

function getEventLocationV1(event) {
  // so it's backwards compatible with v1 events if we go to v2 with outlook
  const location = getEventRawData(event)?.location;

  if (isTypeObject(location) && location?.displayName) {
    return location.displayName;
  }

  return location;
}

export function addEventLocation(event, location) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.location = location;
      return event;
    default:
      return addRawJsonProperty({ event, key: "location", value: location });
  }
}

export function getEventEtag(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.etag ?? getEventEtagV1(event);
    default:
      return getEventEtagV1(event);
  }
}

function getEventEtagV1(event) {
  return getEventRawData(event)?.etag;
}

export function getEventStatus(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.status ?? getEventStatusV1(event);
    default:
      return getEventStatusV1(event);
  }
}

function getEventStatusV1(event) {
  return getEventRawData(event)?.status;
}

export function getEventWebLink(event) {
  return event.web_link;
}

export function getEventCreatedAt(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.created_at ?? getEventCreatedAtV1(event);
    default:
      return getEventCreatedAtV1(event);
  }
}

function getEventCreatedAtV1(event) {
  return getEventRawData(event)?.created;
}

export function getEventTitleProtected(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.title || getEventTitleV1(event) || "";
    default:
      return getEventTitleV1(event) || "";
  }
}

export function getEventTitle(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.title ?? getEventTitleV1(event);
    default:
      return getEventTitleV1(event);
  }
}

function getEventTitleV1(event) {
  return getEventRawData(event)?.summary;
}

export function addEventTitle(event, title) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.title = title;
      return event;
    default:
      return addRawJsonProperty({ event, key: "summary", value: title });
  }
}

export function getEventDescription(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.description ?? getEventDescriptionV1(event);
    default:
      return getEventDescriptionV1(event);
  }
}

function getEventDescriptionV1(event) {
  return getEventRawData(event)?.description;
}

export function addEventDescription(event, description) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.description = description;
      return event;
    default:
      return addRawJsonProperty({ event, key: "description", value: description });
  }
}

//* this only exists in v2
export function getEventColorHex(event) {
  return event?.event_color;
}

export function getEventCreator(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.creator ?? getEventCreatorV1(event);
    default:
      return getEventCreatorV1(event);
  }
}

function getEventCreatorV1(event) {
  return getEventRawData(event)?.creator;
}

export function getEventOrganizer(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.organizer ?? getEventOrganizerV1(event);
    default:
      return getEventOrganizerV1(event);
  }
}

function getEventOrganizerV1(event) {
  return getEventRawData(event)?.organizer;
}

/**
 * @param {VimcalEvent | VimcalPrivateEvent | undefined | null} event
 */
export function getEventStart(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.event_start ?? getEventStartV1(event); // need second condition for master recurring events
    default:
      return getEventStartV1(event);
  }
}

/**
 * @param {VimcalEvent | VimcalPrivateEvent | undefined | null} event
 * @returns {VimcalEventTime | undefined}
 */
function getEventStartV1(event) {
  return getEventRawData(event)?.start;
}

export function addEventStart({ event, start, timeZone }) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.event_start = start;
      if (timeZone && event?.start) {
        event.start.timeZone = timeZone;
      }

      return event;
    default:
      addRawJsonDateProperty({ event, key: "start", value: start, timeZone });
  }
}

/**
 * @param {VimcalEvent | VimcalPrivateEvent | undefined | null} event
 */
export function getEventEnd(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.event_end ?? getEventEndV1(event); // need second condition for master recurring events
    default:
      return getEventEndV1(event);
  }
}

/**
 * @param {VimcalEvent | VimcalPrivateEvent | undefined | null} event
 * @returns {VimcalEventTime | undefined}
 */
function getEventEndV1(event) {
  return getEventRawData(event)?.end;
}

export function addEventEnd({ event, end, timeZone }) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.event_end = end;
      if (timeZone && event?.end) {
        event.end.timeZone = timeZone;
      }

      return event;
    default:
      addRawJsonDateProperty({ event, key: "end", value: end, timeZone });
  }
}

export function getEventRecurrence(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.recurrence ?? getEventRecurrenceV1(event); // need second condition for master recurring events
    default:
      return getEventRecurrenceV1(event);
  }
}

function getEventRecurrenceV1(event) {
  return getEventRawData(event)?.recurrence;
}

export function addEventRecurrence(event, recurrence) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.recurrence = recurrence;
      return event;
    default:
      return addRawJsonProperty({
        event,
        key: "recurrence",
        value: recurrence,
      });
  }
}

// used for recurring
export function getEventMasterEventID(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.master_event_id ?? getEventMasterEventIDV1(event);
    default:
      return getEventMasterEventIDV1(event);
  }
}

function getEventMasterEventIDV1(event) {
  return getEventRawData(event)?.recurringEventId;
}

export function getEventOriginalStartTime(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.original_start_time ?? getEventOriginalStartTimeV1(event);
    default:
      return getEventOriginalStartTimeV1(event);
  }
}

function getEventOriginalStartTimeV1(event) {
  return getEventRawData(event)?.originalStartTime;
}

export function getEventType(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.show_as ?? getEventTypeV1(event);
    default:
      return getEventTypeV1(event);
  }
}

function getEventTypeV1(event) {
  return getEventRawData(event)?.eventType;
}

export function getEventTransparency(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      if (isOutOfOfficeEvent(event)) {
        return OUT_OF_OFFICE_DURING_EVENT;
      }
      return event?.transparency ?? getEventTransparencyV1(event);
    default:
      return getEventTransparencyV1(event);
  }
}

function getEventTransparencyV1(event) {
  return getEventRawData(event)?.transparency;
}

export function addEventTransparency(event, transparency) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.transparency = transparency;
      return event;
    default:
      return addRawJsonProperty({
        event,
        key: "transparency",
        value: transparency,
      });
  }
}

export function getEventOutOfOfficeDeclineMode(event) {
  return event?.ooo_decline_mode ?? OUT_OF_OFFICE_AUTO_DECLINE_NONE;
}

export function getEventOutOfOfficeDeclineMessage(event) {
  return event?.ooo_decline_message ?? "";
}

export function getEventVisibility(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.visibility ?? getEventVisibilityV1(event);
    default:
      return getEventVisibilityV1(event);
  }
}

function getEventVisibilityV1(event) {
  return getEventRawData(event)?.visibility;
}

export function addEventVisibility(event, visibility) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.visibility = visibility;
      return event;
    default:
      return addRawJsonProperty({
        event,
        key: "visibility",
        value: visibility,
      });
  }
}

export function getEventICalUID(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.ical_uid ?? getEventICalUIDV1(event);
    default:
      return getEventICalUIDV1(event);
  }
}

function getEventICalUIDV1(event) {
  return getEventRawData(event)?.iCalUID;
}

export function getAttendeeEmails(event) {
  const attendees = getEventAttendees(event);
  if (isEmptyArrayOrFalsey(attendees)) {
    return [];
  }
  return attendees
    .filter((attendee) => getObjectEmail(attendee))
    .map((attendee) => getObjectEmail(attendee));
}

export function getAttendeesString(event) {
  const attendeesArray = getAttendeeEmails(event);
  if (isEmptyArrayOrFalsey(attendeesArray)) {
    return "";
  }
  return attendeesArray.sort().join("_");
}

export function getEventAttendees(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.attendees ?? getEventAttendeesV1(event);
    default:
      return getEventAttendeesV1(event);
  }
}

function getEventAttendeesV1(event) {
  return getEventRawData(event)?.attendees;
}

export function addEventAttendees(event, attendees) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.attendees = attendees;
      return event;
    default:
      return addRawJsonProperty({ event, key: "attendees", value: attendees });
  }
}

//* only in v2
export function getEventAttendeeOmitted(event) {
  return event?.attendees_omitted;
}

export function getEventExtendedProperties(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.extended_properties ?? getEventExtendedPropertiesV1(event);
    default:
      return getEventExtendedPropertiesV1(event);
  }
}

function getEventExtendedPropertiesV1(event) {
  return getEventRawData(event)?.extendedProperties;
}

export function getEventConferenceURL(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.conference_url ?? getEventConferenceURLV1(event);
    default:
      // for v1, this only exists for google hangout url
      return getEventConferenceURLV1(event);
  }
}

function getEventConferenceURLV1(event) {
  return getEventRawData(event)?.hangoutLink;
}

export function getEventPrivateCopy(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.private_copy ?? getEventPrivateCopyV1(event);
    default:
      return getEventPrivateCopyV1(event);
  }
}

function getEventPrivateCopyV1(event) {
  return getEventRawData(event)?.privateCopy;
}

export function getEventConferenceData(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.conference_data ?? getEventConferenceDataV1(event);
    default:
      return getEventConferenceDataV1(event);
  }
}

function getEventConferenceDataV1(event) {
  return getEventRawData(event)?.conferenceData;
}

export function addEventConferenceData(event, conferenceData) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      event.conference_data = conferenceData;
      return event;
    default:
      return addRawJsonProperty({
        event,
        key: "conferenceData",
        value: conferenceData,
      });
  }
}

export const ANYONE_CAN_ADD_SELF = isVersionV2()
  ? "anyone_can_add_self"
  : "anyoneCanAddSelf";
export const GUESTS_CAN_INVITE_OTHERS = isVersionV2()
  ? "guests_can_invite_others"
  : "guestsCanInviteOthers";
export const GUESTS_CAN_MODIFY = isVersionV2()
  ? "guests_can_modify"
  : "guestsCanModify";
export const GUESTS_CAN_SEE_OTHER_GUESTS = isVersionV2()
  ? "guests_can_see_other_guests"
  : "guestsCanSeeOtherGuests";

export function getEventGuestPermissions(event, permission) {
  // v2 values:
  // {
  //   anyone_can_add_self: event['anyoneCanAddSelf'],
  //   guests_can_invite_others: event['guestsCanInviteOthers'],
  //   guests_can_modify: event['guestsCanModify'],
  //   guests_can_see_other_guests: event['guestsCanSeeOtherGuests'],
  // }
  // v1 values: anyoneCanAddSelf, guestsCanInviteOthers, guestsCanModify, guestsCanSeeOtherGuests
  switch (BACKEND_VERSION) {
    case VERSION_2:
      const guestPermssions = event?.guest_permissions;
      const v2GuestPermissions = guestPermssions
        ? guestPermssions[permission]
        : undefined;

      return (
        v2GuestPermissions ?? getEventGuestPermissionsV1(event, permission)
      );
    default:
      return getEventGuestPermissionsV1(event, permission);
  }
}

function getEventGuestPermissionsV1(event, permission) {
  return getEventRawData(event)?.[permission];
}

export function getEventIsRemindersOn(event) {
  if (isOutlookEvent(event)) {
    return event?.is_reminder_on;
  }

  return true;
}

export function getEventReminders(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.reminders ?? getEventRemindersV1(event);
    default:
      return getEventRemindersV1(event);
  }
}

function getEventRemindersV1(event) {
  return getEventRawData(event)?.reminders;
}

export function getEventAttachments(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.attachments ?? getEventAttachmentsV1(event);
    default:
      return getEventAttachmentsV1(event);
  }
}

function getEventAttachmentsV1(event) {
  return getEventRawData(event)?.attachments;
}

export function getEventUpdatedAt(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.updated_at ?? getEventUpdatedAtV1(event);
    default:
      return getEventUpdatedAtV1(event);
  }
}

function getEventUpdatedAtV1(event) {
  return getEventRawData(event)?.updated;
}

// this is the id that we create on the client
export function getClientEventID(event) {
  return event?.id;
}

export function getEventID(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.provider_id ?? getEventIDV1(event);
    default:
      return getEventIDV1(event);
  }
}

function getEventIDV1(event) {
  return getEventRawData(event)?.id;
}

// this is only for v1
export function getEventColorID(event) {
  return event?.color_id ?? getEventRawData(event)?.colorId;
}

export function addEventColorID(event, colorID) {
  event.color_id = colorID;
  return event;
}

export function addEventUserCalendarID(event, ID) {
  if (isEmptyObjectOrFalsey(event)) {
    return {};
  }

  event.user_calendar_id = ID;
  return event;
}

export function getEventUserCalendarID(event) {
  return event?.user_calendar_id;
}

export function addEventUserEventID(event, ID) {
  if (isEmptyObjectOrFalsey(event)) {
    return {};
  }

  event.user_event_id = ID;
  return event;
}

export function getEventUserEventID(event) {
  return event?.user_event_id;
}

//! Need this for recurring events
export function getEventRawData(event) {
  return event?.raw_json;
}

export function getEventProvider(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.provider ?? getEventProviderV1(event);
    default:
      return getEventProviderV1(event);
  }
}

function getEventProviderV1(event) {
  return event?.provider;
}

export function getGCalEventId(event) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return event?.provider_id ?? getGCalEventIdV1(event);
    default:
      return getGCalEventIdV1(event);
  }
}

function getGCalEventIdV1(event) {
  return event?.gcal_event_id;
}

export function getEventUniqueEtag(event) {
  return event?.uniqueEtag;
}

export function isAllDayOutlookEvent(event) {
  if (!isOutlookEvent(event)) {
    return false;
  }
  return event?.all_day ?? getEventRawData(event)?.isAllDay;
}

export function getGoogleEventHTMLLink(event) {
  return event?.html_link ?? getEventRawData(event)?.htmlLink;
}

export function getEventBackgroundColor(event) {
  return event?.backgroundColor;
}

export function isOrganizer(event) {
  return event?.is_organizer;
}

export function getEventUserEmail(event) {
  return event?.user_email ?? event?.userEmail;
}

export function getOutlookEventShowAs(event) {
  if (!isOutlookEvent(event)) {
    return;
  }
  return event?.show_as || getEventRawData(event)?.showAs;
}

/**
 * @param {VimcalEvent | VimcalPrivateEvent} event
 * @returns {string[] | undefined}
 */
export function getEventCategories(event) {
  if (!isOutlookEvent(event)) {
    return;
  }
  return event?.categories;
}

function addRawJsonProperty({ event, key, value }) {
  if (getEventRawData(event)) {
    event.raw_json[key] = value;
  } else {
    event.raw_json = {};
    event.raw_json[key] = value;
  }

  return event;
}

function addRawJsonDateProperty({ event, key, value, timeZone }) {
  if (getEventRawData(event)) {
    event.raw_json[key] = value;
  } else {
    event.raw_json = {};
    event.raw_json[key] = value;
  }

  if (timeZone && getEventRawData(event)?.start) {
    event.raw_json.start.timeZone = timeZone;
  }

  return event;
}

export function getEventAttendeeComment(attendee) {
  return attendee?.comment || "";
}
