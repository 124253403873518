import { getObjectEmail } from "../../lib/objectFunctions";
import { getAccountName } from "../../lib/userFunctions";
import { convertTrueFalseStringIntoValue } from "../commonUsefulFunctions";
import { DEFAULT_HOLD_TITLE } from "../holdFunctions";
import { isEmptyObjectOrFalsey } from "../typeGuards";

/***************/
/* Magic Links */
/***************/

export function getMagicLinkConsumed({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return false;
  }

  return magicLink?.consumed;
}

export function getMagicLinkExpired({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return false;
  }

  return magicLink?.expired;
}

export function getMagicLinkEmail({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return "";
  }

  return getObjectEmail(magicLink);
}

export function getMagicLinkInviterName({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return {
      firstName: "",
      lastName: "",
      fullName: "",
      userName: "",
    };
  }

  return getAccountName({ masterAccount: magicLink?.master_account });
}

export function getMagicLinkInviteeName({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return {
      firstName: "",
      lastName: "",
    };
  }

  return {
    firstName: magicLink?.first_name,
    lastName: magicLink?.last_name,
  };
}

export function getMagicLinkMasterAccount({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return null;
  }

  return magicLink?.master_account;
}

export function getMagicLinkToken({ magicLink }: { magicLink: MagicLink }) {
  if (isEmptyObjectOrFalsey(magicLink)) {
    return null;
  }

  return magicLink?.token;
}

/******************/
/* Hold Accessors */
/******************/

export function getGroupVoteLinkEventsHold({ bookingLink }) {
  if (isEmptyObjectOrFalsey(bookingLink)) {
    return {};
  }

  return bookingLink.events_hold ?? {};
}

/**********************************************************/
/* events_hold is a property on group vote / spreadsheet */
/**********************************************************/

export function getEventsHoldToken({ eventsHold }) {
  if (isEmptyObjectOrFalsey(eventsHold)) {
    return null;
  }

  return eventsHold?.token;
}

export function getEventsHoldColor({ eventsHold }) {
  if (isEmptyObjectOrFalsey(eventsHold)) {
    /* Should we default this to a numerical value? */
    /* Input seems okay with null */
    return null;
  }

  // https://google-calendar-simple-api.readthedocs.io/en/latest/colors.html
  return eventsHold?.google_color_id;
}

export function getEventsHoldTitle({ eventsHold }) {
  if (isEmptyObjectOrFalsey(eventsHold)) {
    return DEFAULT_HOLD_TITLE;
  }

  return eventsHold?.title;
}

/**************************************************************/
/* hold_details is a property on event (see holdFunctions.js) */
/**************************************************************/

export function getHoldDetailsID({ holdDetails }) {
  if (isEmptyObjectOrFalsey(holdDetails)) {
    return null;
  }

  return holdDetails?.vholds_id;
}

export function getHoldDetailsLinkable({ holdDetails }) {
  if (isEmptyObjectOrFalsey(holdDetails)) {
    return "";
  }

  return holdDetails?.linkable;
}

export function getHoldDetailsType({ holdDetails }) {
  if (isEmptyObjectOrFalsey(holdDetails)) {
    return null;
  }

  return holdDetails?.linkable_type;
}

/*********************/
/* Connected Account */
/*********************/

export function getUserConnectedAccountToken({ user }) {
  return user?.connected_account_token;
}

export function isUserFromMagicLink({ user }) {
  /* Force into a boolean */
  return !!getUserConnectedAccountToken({ user });
}

export function getUserConnectedAccountDetails({ user }) {
  return user?.connected_account_details ?? {};
}

export function getAccountShowMagicLinkOnboarding({ masterAccount }) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(masterAccount?.show_magic_link_onboarding);
}

export function getConnectedAccountProfilePicture({ user }) {
  return getUserConnectedAccountDetails({ user })?.master_account?.internal_profile_photo_url;
}
