import {
  getPrimaryCalendarColor,
  shouldCalendarBeShown,
  removeDuplicatesFromArray,
  isEmailAGroupContact,
} from "../services/commonUsefulFunctions";
import GoogleColors from "../services/googleColors";
import { DEFAULT_PRIMARY_CALENDAR_COLOR } from "../services/globalVariables";
import { EDITABLE_ROLES, OWNER_ROLE } from "../services/googleCalendarService";
import {
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../services/maestroFunctions";
import {
  addCalendarSelected,
  getCalendarBackgroundColor,
  getCalendarColorID,
  getCalendarDefaultReminders,
  getCalendarEditRole,
  getCalendarProviderId,
  getCalendarIsHidden,
  getCalendarIsPrimary,
  getCalendarIsSelected,
  getCalendarSummary,
  getCalendarUserCalendarID,
  getCalendarEmail,
  getCalendarColorHex,
  getCalendarObject,
  getCalendarAllowedMeetingProviders,
  getCalendarOwnerEmail,
  isMatchingCalendarEmailOrUserEmail,
} from "../services/calendarAccessors";
import {getSelfAttendingStatus } from "./eventFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { CALENDAR_PROVIDERS } from "./vimcalVariables";
import { PROVIDER_TYPES, convertOutlookConferencingToHumanReadable } from "./outlookFunctions";
import { getUserEmail, getUserName } from "./userFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { equalAfterTrimAndLowerCased, formatEmail, isSameEmail, lowerCaseAndTrimString } from "./stringFunctions";

export function determineCalendarColor(calendar) {
  if (isEmptyObjectOrFalsey(calendar)) {
    return DEFAULT_PRIMARY_CALENDAR_COLOR;
  }

  const calendarColorHex = getCalendarColorHex(calendar);

  if (calendarColorHex) {
    return calendarColorHex;
  } else if (getCalendarIsPrimary(calendar)) {
    return getPrimaryCalendarColor(calendar);
  }

  const colorID = getCalendarColorID(calendar);
  const color =
    colorID &&
    GoogleColors.calendar[colorID] &&
    GoogleColors.calendar[colorID].background;
  const rawJsonBackgroundColor = getCalendarBackgroundColor(calendar);

  if (
    rawJsonBackgroundColor &&
    GoogleColors["old_calendar_colors"][colorID] &&
    GoogleColors["old_calendar_colors"][colorID].background !==
      rawJsonBackgroundColor
  ) {
    // if the given calendar background is the same as the old events color -> return new color from colorId index
    return rawJsonBackgroundColor;
  }

  return color || rawJsonBackgroundColor || DEFAULT_PRIMARY_CALENDAR_COLOR;
}

export function getUserCalendar(allCalendars, email) {
  if (isEmptyObjectOrFalsey(allCalendars) || !email) {
    return {};
  }

  let matchingCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (equalAfterTrimAndLowerCased(getCalendarUserEmail(allCalendars[k]), email)) {
      matchingCalendars[k] = allCalendars[k];
    }
  });

  return matchingCalendars;
}

export function getUserPrimaryCalendar({ allCalendars, email }) {
  if (isEmptyObjectOrFalsey(allCalendars) || !email) {
    return {};
  }

  const matchingUserCalendarId = Object.keys(allCalendars).find((k) => {
    return (
      getCalendarUserEmail(allCalendars[k]) === email &&
      getCalendarIsPrimary(allCalendars[k])
    );
  });

  return allCalendars[matchingUserCalendarId];
}

export function createUpdatedAllCalendars(param) {
  const {
    responseAllCalendars,
    allCalendars,
    userEmail, // so we know which user this calendar is from,
    isCleanRefresh,
    currentUser = {},
    isNewUserSync = false,
  } = param;
  let updatedAllCalendars = {};
  const selectedCalendarsIndex =
    getSelectedCalendarIndexFromAllCalendars(allCalendars);

  let selectedOnCalendars = []; // array of calendars that are selected - on
  Object.keys(selectedCalendarsIndex).forEach((userCalendarID) => {
    if (selectedCalendarsIndex[userCalendarID]) {
      selectedOnCalendars = selectedOnCalendars.concat(userCalendarID);
    }
  });

  if (!isEmptyObjectOrFalsey(responseAllCalendars)) {
    Object.keys(responseAllCalendars).forEach((k) => {
      if (
        !isValidCalendar(responseAllCalendars[k]) ||
        !shouldCalendarBeShown(responseAllCalendars[k])
      ) {
        return;
      }

      updatedAllCalendars[k] = responseAllCalendars[k];
      updatedAllCalendars[k].userEmail = userEmail;

      if (isNewUserSync) {
        // on new user initial sync -> set main calendars to selected
        // on login to new user -> keep selected of previous login.
        // for maestro limited users -> only select the new calendar (proxy only returns one calendar)
        // Required to prevent other calendars from being selected (each sync enters if we only have limited access check)
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected:
            (selectedCalendarsIndex[k] ||
              (getCalendarIsPrimary(updatedAllCalendars[k]) && isMatchingCalendarEmailOrUserEmail(updatedAllCalendars[k], getUserEmail(currentUser))) ||
              (isUserDelegatedUser(currentUser) &&
                isMatchingCalendarEmailOrUserEmail(updatedAllCalendars[k], getUserEmail(currentUser)))
              ) ?? // hacky way of getting the primary calendar
            false,
        });
      } else if (selectedOnCalendars.length === 0 && isCleanRefresh) {
        // if it's clean refresh and nothing is selected -> toggle on primary calendar for current user
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: !!getCalendarIsPrimary(updatedAllCalendars[k]) && isMatchingCalendarEmailOrUserEmail(updatedAllCalendars[k], getUserEmail(currentUser)),
        });
      } else {
        updatedAllCalendars[k] = addCalendarSelected({
          calendar: updatedAllCalendars[k],
          selected: selectedCalendarsIndex[k] || false,
        });
      }
    });
  }

  if (!isEmptyObjectOrFalsey(allCalendars)) {
    if (isCleanRefresh) {
      let filteredCalendars = {};
      Object.keys(allCalendars).forEach((k) => {
        const calendar = allCalendars[k];
        if (getCalendarUserEmail(calendar) !== userEmail) {
          // only add if it's a different email on complete sync
          filteredCalendars[k] = calendar;
        }
      });
      updatedAllCalendars = { ...filteredCalendars, ...updatedAllCalendars };
    } else {
      updatedAllCalendars = { ...allCalendars, ...updatedAllCalendars };
    }
  }

  return updatedAllCalendars;
}

export function parseSyncResponseCalendars(
  calendarList,
  lastSyncedAtUtc,
  allCalendars
) {
  // also need to add last_synced_at_utc
  if (isEmptyArray(calendarList)) {
    return {
      updatedAllCalendars: {},
      calendarContacts: [],
      primaryCalendarCount: 0,
    };
  }

  let responseAllCalendars = {};
  // contacts info of calendars {email: google_id: name: summary};
  let calendarContacts = [];

  let primaryCalendarCount = 0;
  calendarList.forEach((c) => {
    if (c.primary) {
      // this is the one place where we can use .primary since this is before we put .calendar into allCalendars
      primaryCalendarCount += 1;
    }

    calendarContacts = calendarContacts.concat({
      email: c.google_id,
      name: c.summary_override || c.summary,
    });

    const calendarUserCalendarID = getEventUserCalendarID(c);
    responseAllCalendars[calendarUserCalendarID] = {};
    responseAllCalendars[calendarUserCalendarID].calendar = c;

    if (isCalendarSelected(allCalendars[calendarUserCalendarID])) {
      responseAllCalendars[calendarUserCalendarID].calendar.last_synced_at_utc =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].calendar.last_synced_at =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].calendar.lastSyncedAt =
        lastSyncedAtUtc;
      responseAllCalendars[calendarUserCalendarID].lastSyncedAt =
        lastSyncedAtUtc;
    }
  });

  return {
    responseAllCalendars,
    calendarContacts,
    primaryCalendarCount,
  };
}

function sortCalendars(calendars) {
  if (isEmptyArray(calendars)) {
    return [];
  }

  return calendars.sort(function (a, b) {
    const nameA = lowerCaseAndTrimString(getCalendarSummary(a) || getCalendarProviderId(a));
    const nameB = lowerCaseAndTrimString(getCalendarSummary(b) || getCalendarProviderId(b));
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

export function getDuplicateCalendarNames(userCalendar, emailToNameIndex) {
  let duplicateNames = [];
  let allNames = [];

  userCalendar.forEach((calendar) => {
    const email = getCalendarProviderId(calendar);
    const name =
      calendar.calendar.summary_override ||
      emailToNameIndex[email] ||
      getCalendarSummary(calendar);
    if (!name) {
      return;
    }

    if (allNames.includes(name)) {
      duplicateNames = duplicateNames.concat(name);
    } else if (!isEmailAGroupContact(email)) {
      // do not add emails like contacts, birthdays, etc
      allNames = allNames.concat(name);
    }
  });

  return duplicateNames;
}

export function getCalendarName({
  calendar,
  emailToNameIndex,
  currentUser,
  masterAccount,
}) {
  if (calendar?.calendar?.name_override) {
    return calendar?.calendar?.name_override;
  } 
  if (calendar?.calendar?.summary_override) {
    return calendar.calendar.summary_override;
  }
  if (calendar?.calendar?.name) {
    return calendar.calendar.name;
  }
  if (currentUser?.email === getCalendarProviderId(calendar)) {
    const { fullName } = getUserName({ user: currentUser, masterAccount });
    if (fullName) {
      return fullName;
    }
  }

  const email = getCalendarProviderId(calendar);
  const calendarSummary = getCalendarSummary(calendar);

  if (emailToNameIndex && emailToNameIndex[calendarSummary]) {
    // sometimes calendar summary can be email
    return emailToNameIndex[calendarSummary];
  } else if (calendarSummary) {
    return calendarSummary;
  } else if (emailToNameIndex && emailToNameIndex[email]) {
    return emailToNameIndex[email];
  }

  if (email) {
    return email.toLowerCase();
  }

  return email;
}

export function isCalendarSelected(calendar) {
  if (isEmptyObjectOrFalsey(calendar)) {
    return false;
  }

  return getCalendarIsSelected(calendar) ?? false;
}

export function isValidCalendar(calendar) {
  if (isVersionV2()) {
    return (
      !!getCalendarUserCalendarID(calendar) && !!getCalendarProviderId(calendar)
    );
  }

  return (
    !!getCalendarUserCalendarID(calendar) &&
    !!getCalendarProviderId(calendar) &&
    !!calendar.calendar.raw_json
  );
}

export function getActiveCalendarEmailsFromAllCalendars({ allCalendars }) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let activeCalendarEmails = [];
  Object.keys(allCalendars).forEach((k) => {
    if (isCalendarSelected(allCalendars[k])) {
      activeCalendarEmails = activeCalendarEmails.concat(
        getCalendarProviderId(allCalendars[k])
      );
    }
  });

  return activeCalendarEmails;
}

export function getActiveCalendarsIDsFromAllCalendars({
  allCalendars,
  currentUserEmail,
  isInitialSync = false,
}) {
  return getActiveCalendarsFromAllCalendars(
    allCalendars,
    currentUserEmail,
    isInitialSync,
  ).map((c) => getCalendarUserCalendarID(c));
}

export function getActiveCalendarsForUser({
  allCalendars,
  userEmail,
  upcomingUserCalendarIDs,
  skipPrimaryCheck = false, // do not add if primary calendar
}) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let activeCalendars = [];
  Object.keys(allCalendars).forEach((k) => {
    const calendar = allCalendars[k];
    if (
      isSameEmail(getCalendarUserEmail(calendar), userEmail)
      && (isCalendarSelected(calendar)
        || (!skipPrimaryCheck && getCalendarIsPrimary(calendar))
        || upcomingUserCalendarIDs?.includes(getCalendarUserCalendarID(calendar))
      )
    ) {
      activeCalendars = activeCalendars.concat(calendar);
    }
  });

  return activeCalendars;
}

export function getActiveCalendarsFromAllCalendars(
  allCalendars,
  currentUserEmail,
  isInitialSync = false,
  isUserDelegatedUser = false
) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let activeCalendars = [];
  Object.keys(allCalendars).forEach((k) => {
    if (
      isCalendarSelected(allCalendars[k]) ||
      (getCalendarIsPrimary(allCalendars[k]) &&
        getCalendarUserEmail(allCalendars[k]) === currentUserEmail &&
        isInitialSync) ||
      isUserDelegatedUser
    ) {
      activeCalendars = activeCalendars.concat(allCalendars[k]);
    }
  });

  return activeCalendars;
}

export function getCalendarsFromCalendarID(calendarIDs, allCalendars) {
  // calendarIDs comes in as an array
  if (isEmptyArray(calendarIDs)) {
    return {};
  }

  let filteredCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (calendarIDs.includes(k)) {
      filteredCalendars[k] = allCalendars[k];
    }
  });

  return filteredCalendars;
}

export function getMatchingCalendarWithCalendarOwnerFromEmail({
  email,
  allCalendars,
  checkForPrimaryOnly,
  checkForMatchingCalendarOnProviderID,
}) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return Object.values(allCalendars)?.find((calendar) => {
    if (checkForPrimaryOnly) {
      return getCalendarIsPrimary(calendar) && equalAfterTrimAndLowerCased(getCalendarOwnerEmail(calendar), email);
    }
    if (checkForMatchingCalendarOnProviderID 
      && equalAfterTrimAndLowerCased(getCalendarProviderId(calendar), email)
    ) {
      return true;
    }
    return equalAfterTrimAndLowerCased(getCalendarOwnerEmail(calendar), email);
  });
}

export function getFirstMatchingEditableCalendarFromEmail({
  allCalendars,
  email,
}) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  let matchingCalendar;
  Object.values(allCalendars).forEach((calendar) => {
    if (isGoogle(calendar)) {
      if (matchingCalendar) {
        // find first
        return;
      }
      if (equalAfterTrimAndLowerCased(getCalendarOwnerEmail(calendar), email)) {
        const editRole = getCalendarEditRole(calendar);
        if (EDITABLE_ROLES.includes(editRole)) {
          matchingCalendar = calendar;
        }
      }
    } else {
      // outlook
      if (equalAfterTrimAndLowerCased(getCalendarOwnerEmail(calendar), email) || equalAfterTrimAndLowerCased(getCalendarUserEmail(calendar), email)) {
        if (isOutlook(matchingCalendar) && !getCalendarIsPrimary(matchingCalendar)) {
          // do nothing -> skip
          // if found outlook and not primary calendar -> keep looking
        } else if (matchingCalendar) {
          // find first
          return;
        }

        const editRole = getCalendarEditRole(calendar);
        if (EDITABLE_ROLES.includes(editRole)) {
          matchingCalendar = calendar;
        }
      }
    }
  });

  return matchingCalendar;
}

export function getCalendarFromEmail(email, allCalendars) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  let matchingCalendar;
  Object.values(allCalendars).forEach((calendar) => {
    if (!isMatchingCalendarEmailOrUserEmail(calendar, email)) {
      return;
    }
    if (!matchingCalendar) {
      matchingCalendar = calendar;
    } else if (getCalendarIsPrimary(calendar) && isSameEmail(getCalendarProviderId(calendar), email)) {
      matchingCalendar = calendar;
    } else if (getCalendarIsPrimary(calendar) && !getCalendarIsPrimary(matchingCalendar)) {
      // set if it's primary calendar
      matchingCalendar = calendar;
    }
  });

  return matchingCalendar;
}

export function getCurrentUserDefaultColor(currentUserEmail, allCalendars) {
  const currentUserCalendar = getCalendarFromEmail(
    currentUserEmail,
    allCalendars
  );
  return determineCalendarColor(currentUserCalendar);
}

export function getUserCalendarIDFromEmail(email, allCalendars) {
  if (!email || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  const matchingCalendar = getCalendarFromEmail(email, allCalendars);
  return getCalendarUserCalendarID(matchingCalendar);
}

export function isOwnerOfCalendar(allCalendars, userCalendarID) {
  return getCalendarEditRole(allCalendars[userCalendarID]) === OWNER_ROLE;
}

export function hasWriteAccessToUserCalendarID(userCalendarID, allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars) || !userCalendarID) {
    return false;
  }

  return EDITABLE_ROLES.includes(
    getCalendarEditRole(allCalendars[userCalendarID])
  );
}

export function getAllEditableCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let writableCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (EDITABLE_ROLES.includes(getCalendarEditRole(allCalendars[k]))) {
      writableCalendars[k] = allCalendars[k];
    }
  });

  return writableCalendars;
}

export function getMainCalendarEmails(mainCalendarUserIds, allCalendars) {
  if (
    isEmptyArray(mainCalendarUserIds) ||
    isEmptyObjectOrFalsey(allCalendars)
  ) {
    return [];
  }

  let mainCalendarEmails = [];
  Object.keys(allCalendars).forEach((k) => {
    if (mainCalendarUserIds.includes(k)) {
      mainCalendarEmails = mainCalendarEmails.concat(
        getCalendarProviderId(allCalendars[k])
      );
    }
  });

  return mainCalendarEmails;
}

export function createEmailsFromActiveCalendars(activeCalendars) {
  if (isEmptyObjectOrFalsey(activeCalendars)) {
    return [];
  }

  let emails = [];

  Object.keys(activeCalendars).forEach((k) => {
    emails = emails.concat(getCalendarProviderId(activeCalendars[k]));
  });

  return emails;
}

export function getAllCalendarUserCalendarIDs(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return Object.keys(allCalendars);
}

export function getUserEmailFromListOfCalendarIDs(
  userCalendarIDList,
  allCalendars
) {
  // given list of user calendar ids -> emails
  if (!userCalendarIDList || userCalendarIDList.length === 0) {
    return [];
  }

  let emailList = [];
  Object.keys(allCalendars).forEach((k) => {
    if (userCalendarIDList.includes(k)) {
      emailList = emailList.concat(getCalendarUserEmail(allCalendars[k]));
    }
  });

  return removeDuplicatesFromArray(emailList);
}

export function getHumanReadableEmailFromUserCalendarID(userCalendarID, allCalendars) {
  if (!userCalendarID || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return getCalendarEmail(allCalendars[userCalendarID]);
}

export function getEmailFromUserCalendarID(userCalendarID, allCalendars) {
  if (!userCalendarID || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  return getCalendarProviderId(allCalendars[userCalendarID]);
}

export function doesMainCalendarsIncludeUserCalendarID(
  mainCalendars,
  userCalendarID
) {
  if (!mainCalendars || mainCalendars.length === 0 || !userCalendarID) {
    return false;
  }

  return mainCalendars.includes(userCalendarID);
}

export function getAllPrimaryCalendarsFromAllCalendars(allCalendars) {
  let primaryCalendars = [];
  Object.keys(allCalendars).forEach((k) => {
    if (getCalendarIsPrimary(allCalendars[k])) {
      primaryCalendars = primaryCalendars.concat(
        getCalendarUserCalendarID(allCalendars[k])
      );
    }
  });

  return primaryCalendars;
}

export function getListOfCalendarsFromListOfuserCalendarIDs(
  userCalendarIDs,
  allCalendars
) {
  if (
    !userCalendarIDs ||
    userCalendarIDs.length === 0 ||
    isEmptyObjectOrFalsey(allCalendars)
  ) {
    return {};
  }

  let matchingCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    if (userCalendarIDs.includes(k)) {
      matchingCalendars[k] = allCalendars[k];
    }
  });

  return matchingCalendars;
}

export function getEventAttendeeStatus(event, allCalendars) {
  if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  const matchingCalendar = allCalendars[getEventUserCalendarID(event)];
  const email = getCalendarProviderId(matchingCalendar);
  return getSelfAttendingStatus(event, email);
}

export function getOrderedAllCalendars(allCalendars, currentUserEmail) {
  // returns array inorder to keep order and have current user calendar at the top
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  const currentUserCalendar = getCalendarFromEmail(
    currentUserEmail,
    allCalendars
  );
  let allCalendarsExceptCurrentUserCalendar = [];

  Object.values(allCalendars).forEach((calendar) => {
    if (
      getCalendarUserCalendarID(calendar) ===
        getCalendarUserCalendarID(currentUserCalendar) ||
      getCalendarIsHidden(calendar)
    ) {
      // skip so we don't add the current user calendar twice
      // we skip hidden calendars
      return;
    }

    allCalendarsExceptCurrentUserCalendar =
      allCalendarsExceptCurrentUserCalendar.concat(calendar);
  });
  const sortedCalendars = sortCalendars(allCalendarsExceptCurrentUserCalendar);

  if (currentUserCalendar) {
    return [currentUserCalendar].concat(sortedCalendars); // current user calendar comes first
  } else {
    return sortedCalendars;
  }
}

export function getUserEmailFromEvent(event, allCalendars) {
  // get the user account that the event belongs to
  return getUserEmailFromUserCalendarID(
    getEventUserCalendarID(event),
    allCalendars
  );
}

export function getMatchingUserFromEvent({
  event,
  allCalendars,
  allLoggedInUsers,
}) {
  const matchingUserEmail = getUserEmailFromEvent(event, allCalendars);
  return allLoggedInUsers?.find((user) => isSameEmail(getUserEmail(user), matchingUserEmail));
}

export function getUserEmailFromEventWithCurrentUserBackup({
  event,
  allCalendars,
  currentUser,
}) {
  return (
    getUserEmailFromUserCalendarID(
      getEventUserCalendarID(event),
      allCalendars
    ) ?? currentUser?.email
  );
}

export function getUserEmailFromUserCalendarID(userCalendarID, allCalendars) {
  return getCalendarUserEmail(allCalendars[userCalendarID]);
}

export function isUserCalendarIDFromPrimaryCalendar(
  userCalendarID,
  allCalendars
) {
  const matchingCalendar = allCalendars[userCalendarID];
  return getCalendarIsPrimary(matchingCalendar);
}

export function getAllUserCalendarIDWithDefaultReminders(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  let userCalendarIDWithDefaultReminders = [];
  Object.keys(allCalendars).forEach((k) => {
    if (getCalendarDefaultReminders(allCalendars[k])?.length > 0) {
      userCalendarIDWithDefaultReminders =
        userCalendarIDWithDefaultReminders.concat(k);
    }
  });

  return userCalendarIDWithDefaultReminders;
}

export function isCalendarWriteAccess(calendar) {
  return EDITABLE_ROLES.includes(getCalendarEditRole(calendar));
}

export function isCalendarSecondaryAccount( // if it's one of the other logged in calendars
  calendar,
  allLoggedInUsers,
  currentUser
) {
  if (
    isEmptyObjectOrFalsey(calendar) ||
    isEmptyObjectOrFalsey(calendar.calendar) ||
    isEmptyArray(allLoggedInUsers)
  ) {
    return;
  }
  const email = getCalendarOwnerEmail(calendar);

  const loggedInUserEmails = allLoggedInUsers
    .filter((user) => !isSameEmail(getUserEmail(user), getUserEmail(currentUser)))
    .map((user) => getUserEmail(user));
  return loggedInUserEmails.includes(email);
}

export function isCalendarConvertableToSecondary({
  allCalendars,
  allLoggedInUsers,
  calendar,
  currentUser,
  masterAccount,
}) {
  if (
    isEmptyObjectOrFalsey(allCalendars) ||
    isEmptyObjectOrFalsey(allLoggedInUsers) ||
    isEmptyObjectOrFalsey(calendar) ||
    isEmptyObjectOrFalsey(masterAccount)
  ) {
    return false;
  }

  // Need this since it's reliant on the backend
  // note: if the stripe email is different (@vimcal vs @weveapp), then it won't get added as secondary account
  // const isCalendarFromMasterAccount = Object.keys(
  //   getUserCalendar(allCalendars, masterAccount.stripe_email)
  // ).includes(getCalendarUserCalendarID(calendar));
  const calendarOwnerEmail = getCalendarOwnerEmail(calendar);
  const isCalendarGroupCalendar = getCalendarProviderId(calendar)?.includes(".google.com");

  return (
    (
      isCalendarWriteAccess(calendar) &&
      isUserMaestroUser(masterAccount) &&
      // isCalendarFromMasterAccount &&
      calendarOwnerEmail !== currentUser.email &&
      !isCalendarGroupCalendar
    ) ||
    isCalendarSecondaryAccount(calendar, allLoggedInUsers, currentUser)
  );
}

export function getOrSetSelectedCalendarsForNewUser({
  allCalendars,
  currentUserEmail,
  isUserDelegatedUserBool,
}) {
  let userCalendarOverrides = {};

  if (isUserDelegatedUserBool) {
    /* Deselect all calendars except primary if user is a delegated user */
    /* Loop through all calendars */
    Object.keys(allCalendars).forEach((k) => {
      /* Set the override for the current calendar id to false unless it's the primary calendar of the delegated account */
      userCalendarOverrides[k] =
        isSameEmail(getCalendarUserEmail(allCalendars[k]), currentUserEmail) &&
        getCalendarIsPrimary(allCalendars[k]);
    });
  } else {
    /* Keep current selection and add primary calendar of new account */
    /* Loop through all calendars */
    Object.keys(allCalendars).forEach((k) => {
      /* Set the override for the current calendar id to false unless it's one of the following */
      /* It's the primary calendar of the new account */
      /* It's currently selected */
      userCalendarOverrides[k] =
        isCalendarSelected(allCalendars[k]) ||
        (getCalendarUserEmail(allCalendars[k]) === currentUserEmail &&
          getCalendarIsPrimary(allCalendars[k]));
    });
  }

  /* Return the overrides object (key: value is calendarId: true || falsey) */
  return userCalendarOverrides;
}

export function getCalendarUserEmail(calendar) {
  return formatEmail(calendar?.userEmail ?? calendar?.user_email);
}

export function getCalendarFromUserCalendarID({
  userCalendarID,
  allCalendars,
}) {
  return allCalendars[userCalendarID];
}

export function getCalendarFromProviderID({
  allCalendars,
  providerID,
  selectedUser,
}) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return null;
  }

  const matchingCalendars = Object.values(allCalendars).filter(
    c => getCalendarProviderId(c) === providerID
  );

  let matchingCalendar;
  if (selectedUser) {
    matchingCalendar = matchingCalendars.find(
      (c) => equalAfterTrimAndLowerCased(getCalendarUserEmail(c), getUserEmail(selectedUser))
    );
  } else {
    matchingCalendars.forEach(c => {
      if (!matchingCalendar || getCalendarIsPrimary(c)) {
        matchingCalendar = c;
      }
    });
  }

  return isValidCalendar(matchingCalendar) ? matchingCalendar : null;
}

export function getSelectedCalendarIndexFromAllCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let selectedCalendarIndex = {};

  Object.keys(allCalendars).forEach((key) => {
    selectedCalendarIndex[key] = isCalendarSelected(allCalendars[key]);
  });

  return selectedCalendarIndex;
}

export function toggleOffAllCalendars({ allCalendars, exceptionList = [] }) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return {};
  }

  let updatedAllCalendars = {};
  Object.keys(allCalendars).forEach((k) => {
    updatedAllCalendars[k] = addCalendarSelected({
      calendar: allCalendars[k],
      selected: exceptionList.includes(k),
    });
  });

  return updatedAllCalendars;
}

export function isOutlook(calendar) {
  return getCalendarObject(calendar)?.provider === CALENDAR_PROVIDERS.OUTLOOK;
}

export function isGoogle(calendar) {
  return getCalendarObject(calendar)?.provider === CALENDAR_PROVIDERS.GOOGLE;
}

export function getOutlookConferencingReactSelectOptions(calendar) {
  // returns array of options for react-select
  const conferencingOptions = getCalendarAllowedMeetingProviders(
    calendar
  )?.filter((a) => !!a);
  if (!conferencingOptions || conferencingOptions.length === 0) {
    return [];
  }

  return conferencingOptions.map((option) => {
    return {
      value: option,
      label: convertOutlookConferencingToHumanReadable(option),
    };
  });
}

export function getSelectedCalendarEmails(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return Object.values(allCalendars)
    .filter((calendar) => isCalendarSelected(calendar))
    .map((calendar) => getCalendarProviderId(calendar));
}

export function isDefaultOutlookCalendar(calendar) {
  return (
    getCalendarObject(calendar)?.isDefaultCalendar ??
    getCalendarObject(calendar)?.raw_json?.isDefaultCalendar
  );
}

export function getOutlookCalendarDefaultOnlineMeetingProvider(calendar) {
  return (
    getCalendarObject(calendar)?.defaultOnlineMeetingProvider ??
    getCalendarObject(calendar)?.raw_json?.defaultOnlineMeetingProvider
  );
}

export function getCalendarsGroupedByUserEmail({ allCalendars, currentUser }) {
  let orderedUserEmails = [];
  const indexByUserEmail = {};

  if (isEmptyObjectOrFalsey(allCalendars)) {
    return { orderedUserEmails, indexByUserEmail };
  }

  Object.values(allCalendars).forEach((calendar) => {
    if (isEmptyObjectOrFalsey(calendar)) {
      return;
    }

    const { userEmail } = calendar;

    if (indexByUserEmail[userEmail]) {
      if (getCalendarIsPrimary(calendar)) {
        // put primary email at the top
        indexByUserEmail[userEmail] = [calendar].concat(
          indexByUserEmail[userEmail]
        );
      } else {
        indexByUserEmail[userEmail] =
          indexByUserEmail[userEmail].concat(calendar);
      }
    } else if (userEmail) {
      indexByUserEmail[userEmail] = [calendar];
    }
  });

  const userEmails = Object.keys(indexByUserEmail);

  userEmails.forEach((email) => {
    if (email === currentUser.email) {
      orderedUserEmails = [email].concat(orderedUserEmails);
    } else {
      orderedUserEmails = orderedUserEmails.concat(email);
    }
  });

  return { orderedUserEmails, indexByUserEmail };
}

export function getAllCalendarEmails(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  const allEmails = Object.values(allCalendars).map((calendar) =>
    getCalendarEmail(calendar)
  ).filter(email => !!email);
  return removeDuplicatesFromArray(allEmails);
}

export function getEmailsFromMainCalendars({userMainCalendars, userCalendarID}) {
  // mainCalendars is from master account and is array
  if (isEmptyArray(userMainCalendars) || !userCalendarID) {
    return null;
  }
  let name;
  userMainCalendars.forEach(item => {
    item?.main_calendars?.forEach(calendar => {
      if (name) {
        return;
      }
      if (calendar?.user_calendar_id === userCalendarID) {
        if (calendar?.name) {
          name = calendar.name;
        } else if (calendar.provider_id === PROVIDER_TYPES.OUTLOOK) {
          name = calendar.user_email;
        } else {
          name = calendar.google_id;
        }
      }
    });  
  });
  return name;
}

export function getMatchingPrimaryCalendarForUser({allCalendars, user}) {
  const matchingCalendars = getMatchingCalendarsForUser({allCalendars, user});
  return Object.values(matchingCalendars).find((calendar) => getCalendarIsPrimary(calendar));
}

export function getMatchingCalendarsForUser({allCalendars, user, userEmail}) {
  const filteredAllCalendars = {};
  Object.keys(allCalendars).forEach((userCalendarID) => {
    const calendar = allCalendars[userCalendarID];
    if (equalAfterTrimAndLowerCased(getCalendarUserEmail(calendar), (userEmail || getUserEmail(user)))) {
      filteredAllCalendars[userCalendarID] = calendar;
    }
  });
  return filteredAllCalendars;
}

export function getMatchingCalendarsForUserAsAList({allCalendars, user}) {
  const matchingCalendars = getMatchingCalendarsForUser({allCalendars, user});
  return Object.values(matchingCalendars).sort((a, b) => {
    if (getCalendarIsPrimary(a) && !getCalendarIsPrimary(b)) {
      return -1;
    }
    if (!getCalendarIsPrimary(a) && getCalendarIsPrimary(b)) {
      return 1;
    }
    if (isSameEmail(getCalendarEmail(a), getUserEmail(user))) {
      return -1;
    }
    if (isSameEmail(getCalendarEmail(b), getUserEmail(user))) {
      return -1;
    }
    if (getCalendarEmail(a) < getCalendarEmail(b)) {
      return 1;
    }
    if (getCalendarEmail(a) > getCalendarEmail(b)) {
      return -1;
    }
    return 0;
  });
}

/**
 * For now, checking if the email has a custom domain is the most straight-forward way
 * to see if the user is a Workspace user.
 */
export function isGoogleWorkspaceCalendar(calendar) {
  if (!calendar || !isGoogle(calendar)) {
    return false;
  }

  const email = getCalendarUserEmail(calendar);
  return !lowerCaseAndTrimString(email).endsWith('@gmail.com');
}

export function doesCalendarHaveCategories({ calendar, outlookCategories }) {
  if (!isOutlook(calendar) || isEmptyObjectOrFalsey(outlookCategories)) {
    return false;
  }
  const ownerEmail = getCalendarOwnerEmail(calendar);
  return !isEmptyArrayOrFalsey(outlookCategories[ownerEmail]);
}

export function convertUserCalendarIDToProviderIDs({
  userCalendarIDs,
  allCalendars,
}) {
  if (isEmptyArray(userCalendarIDs) || isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }

  return userCalendarIDs.map((userCalendarID) => {
    const matchingCalendar = getCalendarFromUserCalendarID({ userCalendarID, allCalendars });
    return getCalendarProviderId(matchingCalendar);
  }).filter((providerID) => !!providerID);
}

// given a list of userCalendarIDs and user, returns the providerIDs for the user
export function getMatchingUserProviderIDsFromUserCalendarIDs({
  userCalendarIDs,
  userEmail,
  allCalendars,
}) {
  if (isEmptyArray(userCalendarIDs) || !userEmail || isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }
  return userCalendarIDs
    .map(userCalendarID => getCalendarFromUserCalendarID({
      userCalendarID,
      allCalendars,
    }))
    .filter(calendar => isSameEmail(getCalendarUserEmail(calendar), userEmail))
    .map(calendar => getCalendarProviderId(calendar));
}
